import { MobModel } from "types";

import { BASE_STATS } from "data/baseStats";
import conwayShipImage from "assets/mob-conway-ship.svg";
import conwayPilotImage from "assets/mob-conway-pilot.svg";
import grenShipImage from "assets/mob-gren-ship.svg";
import grenPilotImage from "assets/mob-gren-pilot.svg";
import ardinShipImage from "assets/mob-ardin-ship.svg";
import ardinPilotImage from "assets/mob-ardin-pilot.svg";
import sharperShipImage from "assets/mob-sharper-ship.svg";
import sharperPilotImage from "assets/mob-sharper-pilot.svg";
import crossShipImage from "assets/mob-cross-ship.svg";
import crossPilotImage from "assets/mob-cross-pilot.svg";
import ketherShipImage from "assets/mob-kether-ship.svg";
import ketherPilotImage from "assets/mob-kether-pilot.svg";
import gammaShipImage from "assets/mob-gamma-ship.svg";
import gammaPilotImage from "assets/mob-gamma-pilot.svg";
import lockheedShipImage from "assets/mob-lockheed-ship.svg";
import lockheedPilotImage from "assets/mob-lockheed-pilot.svg";
import rinconShipImage from "assets/mob-rincon-ship.svg";
import rinconPilotImage from "assets/mob-rincon-pilot.svg";
import hadronShipImage from "assets/mob-hadron-ship.svg";
import hadronPilotImage from "assets/mob-hadron-pilot.svg";
import acelaShipImage from "assets/mob-acela-ship.svg";
import acelaPilotImage from "assets/mob-acela-pilot.svg";
import megamaShipImage from "assets/mob-megama-ship.svg";
import megamaPilotImage from "assets/mob-megama-pilot.svg";
import breedenShipImage from "assets/mob-breeden-ship.svg";
import breedenPilotImage from "assets/mob-breeden-pilot.svg";
import revinShipImage from "assets/mob-revin-ship.svg";
import revinPilotImage from "assets/mob-revin-pilot.svg";
import valeraShipImage from "assets/mob-valera-ship.svg";
import valeraPilotImage from "assets/mob-valera-pilot.svg";
import zeroShipImage from "assets/mob-zero-ship.svg";
import zeroPilotImage from "assets/mob-zero-pilot.svg";
import avenShipImage from "assets/mob-aven-ship.svg";
import avenPilotImage from "assets/mob-aven-pilot.svg";
import spectraShipImage from "assets/mob-spectra-ship.svg";
import spectraPilotImage from "assets/mob-spectra-pilot.svg";
import radienShipImage from "assets/mob-radien-ship.svg";
import radienPilotImage from "assets/mob-radien-pilot.svg";
import graverShipImage from "assets/mob-graver-ship.svg";
import graverPilotImage from "assets/mob-graver-pilot.svg";
import wraethShipImage from "assets/mob-wraeth-ship.svg";
import wraethPilotImage from "assets/mob-wraeth-pilot.svg";
import arcShipImage from "assets/mob-arc-ship.svg";
import arcPilotImage from "assets/mob-arc-pilot.svg";
import vexenShipImage from "assets/mob-vexen-ship.svg";
import vexenPilotImage from "assets/mob-vexen-pilot.svg";
import tracerShipImage from "assets/mob-tracer-ship.svg";
import tracerPilotImage from "assets/mob-tracer-pilot.svg";
import behemalShipImage from "assets/mob-behemal-ship.svg";
import behemalPilotImage from "assets/mob-behemal-pilot.svg";
import voltaShipImage from "assets/mob-volta-ship.svg";
import voltaPilotImage from "assets/mob-volta-pilot.svg";
import reaverShipImage from "assets/mob-reaver-ship.svg";
import reaverPilotImage from "assets/mob-reaver-pilot.svg";

interface MobData {
  [key: string]: MobModel;
}

const { FIREPOWER, RESILIENCE, SPEED, PRECISION, ENERGY } = BASE_STATS;

export const MOBS: MobData = {
  // BISHOP - LAUNCH CHECK
  conway: {
    name: "Conway",
    images: {
      ship: conwayShipImage,
      pilot: conwayPilotImage,
    },
    dialogs: {
      opening:
        "Hey there, champ. Before you venture out into the vast universe, let's review the battle mechanics you learned at the academy. Afterwards, we'll get you set up with a few ship upgrades.",
      win: "We issued you a set of ship upgrades designed specifically for your ship. Go on ahead to Bishop City to get those upgrades installed. You're ready to go out there and explore!",
      lose: "We issued you a set of ship upgrades designed specifically for your ship. Go on ahead to Bishop City to get those upgrades installed. You're ready to go out there and explore! ...Ready-ish, at least.",
      tutorial: {
        preview:
          "Before a battle, analyze how your stats and skills compare with your opponent. If you win, you will receive rewards based on your battle rating, which depends on how soundly you defeat your opponent.",
        fight: [
          "That green bar to the top left is your ship health. If it goes to zero first, you lose the battle.",
          "Those colorful bars on the bottom show you the how weakened each ship's parts are during battle, which affect its corresponding stat. Example: Weakened weapons mean less firepower. These are fully restored after every battle.",
          "Above that is your fight actions bar. You can hover over each button to see details. The first five buttons are regular attacks, each targeting a specific ship part.",
          "Next are your pilot skills, which have recharge periods after use. Come see me again at the Pilot Guild in the city when you're ready to learn more skills.",
          "Next are supplies you can use to repair your ship health or parts in battle. Then the red button at the end is to attempt an escape.",
          "Lastly, at the top right you'll see the combat log, which you can toggle open or closed.",
          "And that's it! Let's battle!",
        ],
        winResults:
          "When you win in battle, which you did this time, you gain experience and credits. The higher your battle rating, the better your rewards. When you lose in battle, you're sent to the nearest Shipworks for repairs at a higher fee.",
        loseResults:
          "When you lose in battle, which you did this time, you'd normally be sent to the nearest Shipworks for repairs at a higher fee. But this is a review, so we'll repair your ship here for free. When you win in battle, you gain experience and credits. The higher your battle rating, the better your rewards.",
      },
    },
    position: 70,
    heightPercentage: 30,
    level: 1,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [PRECISION, RESILIENCE, FIREPOWER, SPEED, ENERGY],
    isBoss: false,
    isTutorial: true,
    drops: {
      platinum: {
        slug: "medium_repair_kit",
        quantity: 1,
      },
      gold: {
        slug: "medium_restore_module",
        quantity: 1,
      },
      silver: {
        slug: "minor_repair_kit",
        quantity: 1,
      },
      bronze: {
        slug: "minor_restore_module",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 60.5, y: 56.5 },
      targeting: { x: 70.5, y: 56.5 },
      thrusters: { x: 24, y: 50.75 },
    },
    skills: [],
  },
  // BISHOP - HOLLOWLANDS
  gren: {
    name: "Gren",
    images: {
      ship: grenShipImage,
      pilot: grenPilotImage,
    },
    dialogs: {
      opening:
        "I annihilate anyone who dares challenge me. But also please don't challenge me.",
      win: "You win this time, but I'll annihilate you next time. But also please stop challenging me.",
      lose: "I actually beat you!? I mean, of course I did.",
    },
    position: 60,
    heightPercentage: 30,
    level: 1,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "glance_laser",
        quantity: 1,
      },
      gold: {
        slug: "spark_booster",
        quantity: 1,
      },
      silver: {
        slug: "medium_repair_kit",
        quantity: 1,
      },
      bronze: {
        slug: "medium_restore_module",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "glance_laser",
      shields: "glimmer_shield",
      thrusters: "spark_booster",
      targetingSystem: "dark_scope",
      reactorCore: "zero_core",
    },
    partPositions: {
      weapons: { x: 60, y: 55.5 },
      targeting: { x: 70, y: 55.5 },
      thrusters: { x: 31.5, y: 50.75 },
    },
    skills: [],
  },
  ardin: {
    name: "Ardin",
    images: {
      ship: ardinShipImage,
      pilot: ardinPilotImage,
    },
    dialogs: {
      opening:
        "Okay yeah, let's fight. It'll make look good in front of the others when I destroy you.",
      win: "You embarrassed me in front of everybody, I might get demoted ...again.",
      lose: "Woo, I did it! Maybe this will earn me a promotion.",
    },
    position: 90,
    heightPercentage: 30,
    level: 2,
    baseStatsModifiers: {
      firepower: 4,
      resilience: 1,
      speed: 3,
      precision: 0,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 2,
    },
    baseStatsTargets: [FIREPOWER, RESILIENCE, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "burst_laser",
        quantity: 1,
      },
      gold: {
        slug: "pulse_laser",
        quantity: 1,
      },
      silver: {
        slug: "spark_booster",
        quantity: 1,
      },
      bronze: {
        slug: "zero_core",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "burst_laser",
      shields: null,
      thrusters: "spark_booster",
      targetingSystem: null,
      reactorCore: "zero_core",
    },
    partPositions: {
      weapons: { x: 45, y: 55 },
      targeting: { x: 63, y: 55 },
      thrusters: { x: 29, y: 49.5 },
    },
    skills: ["shieldBreaker"],
  },
  sharper: {
    name: "Sharper",
    images: {
      ship: sharperShipImage,
      pilot: sharperPilotImage,
    },
    dialogs: {
      opening: "Ugh, you're challenging me to a fight? I'm trying to nap here.",
      win: "Okay, yay, you defeated me. Now go away and let me sleep.",
      lose: "I told you to leave me alone.",
    },
    position: 120,
    heightPercentage: 30,
    level: 3,
    baseStatsModifiers: {
      firepower: 1,
      resilience: 3,
      speed: 2,
      precision: 4,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 1,
      energy: 3,
    },
    baseStatsTargets: [SPEED, FIREPOWER, PRECISION, RESILIENCE, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "sharp_scope",
        quantity: 1,
      },
      gold: {
        slug: "vision_scope",
        quantity: 1,
      },
      silver: {
        slug: "shimmer_shield",
        quantity: 1,
      },
      bronze: {
        slug: "spark_booster",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "shimmer_shield",
      thrusters: "spark_booster",
      targetingSystem: "sharp_scope",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 68, y: 55.75 },
      targeting: { x: 77, y: 55.75 },
      thrusters: { x: 23.6, y: 49.75 },
    },
    skills: ["eagleEye"],
  },
  cross: {
    name: "Cross",
    images: {
      ship: crossShipImage,
      pilot: crossPilotImage,
    },
    dialogs: {
      opening: "YEAH LET'S FIGHT LET'S GO RIGHT NOW FIGHT FIGHT FIGHT",
      win: "I DIDN'T EXPECT THAT BUT OKAY",
      lose: "LET'S GO AGAIN",
    },
    position: 150,
    heightPercentage: 30,
    level: 4,
    baseStatsModifiers: {
      firepower: 0,
      resilience: 2,
      speed: 1,
      precision: 3,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 3,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, SPEED, FIREPOWER, PRECISION, RESILIENCE],
    isBoss: false,
    drops: {
      platinum: {
        slug: "storm_cell",
        quantity: 1,
      },
      gold: {
        slug: "wave_cell",
        quantity: 1,
      },
      silver: {
        slug: "small_scope",
        quantity: 1,
      },
      bronze: {
        slug: "glimmer_shield",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "glimmer_shield",
      thrusters: null,
      targetingSystem: "small_scope",
      reactorCore: "storm_cell",
    },
    partPositions: {
      weapons: { x: 67, y: 55.25 },
      targeting: { x: 77, y: 55.25 },
      thrusters: { x: 26.2, y: 49 },
    },
    skills: ["systemsDecay", "energyBlast"],
  },
  kether: {
    name: "Kether",
    images: {
      ship: ketherShipImage,
      pilot: ketherPilotImage,
    },
    dialogs: {
      opening: "I'm gonna fly circles around you, slowpoke.",
      win: "I'm not that... fast? Oh my god, my whole identity.",
      lose: "I told you, sluggo.",
    },
    position: 180,
    heightPercentage: 30,
    level: 6,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 0,
      speed: 4,
      precision: 2,
      energy: 1,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 3,
      speed: 1,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [ENERGY, PRECISION, SPEED, RESILIENCE, FIREPOWER],
    isBoss: false,
    drops: {
      platinum: {
        slug: "kinetica",
        quantity: 1,
      },
      gold: {
        slug: "flash_rocket",
        quantity: 1,
      },
      silver: {
        slug: "fade_laser",
        quantity: 1,
      },
      bronze: {
        slug: "dark_scope",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "fade_laser",
      shields: null,
      thrusters: "kinetica",
      targetingSystem: "dark_scope",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 56, y: 53.25 },
      targeting: { x: 66, y: 53.25 },
      thrusters: { x: 35.2, y: 49.5 },
    },
    skills: ["phantomStrike"],
  },
  gamma: {
    name: "Gamma",
    images: {
      ship: gammaShipImage,
      pilot: gammaPilotImage,
    },
    dialogs: {
      opening:
        "My ship can take a million hits, you sure you want to fight me?",
      win: "Okay fine, my ship can take less than a million hits, apparently.",
      lose: "Come back when you're actually ready to do some real damage.",
    },
    position: 210,
    heightPercentage: 30,
    level: 8,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 4,
      speed: 0,
      precision: 1,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, ENERGY, SPEED, PRECISION],
    isBoss: false,
    drops: {
      platinum: {
        slug: "guardian_shield",
        quantity: 1,
      },
      gold: {
        slug: "protector_shield",
        quantity: 1,
      },
      silver: {
        slug: "quick_core",
        quantity: 1,
      },
      bronze: {
        slug: "glance_laser",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "glance_laser",
      shields: "guardian_shield",
      thrusters: null,
      targetingSystem: null,
      reactorCore: "quick_core",
    },
    partPositions: {
      weapons: { x: 80, y: 57.75 },
      targeting: { x: 91, y: 57.75 },
      thrusters: { x: 13, y: 48.5 },
    },
    skills: ["shieldRestore"],
  },
  lockheed: {
    name: "Lockheed",
    images: {
      ship: lockheedShipImage,
      pilot: lockheedPilotImage,
    },
    dialogs: {
      opening: "No one ever wants to fight me. Sure, why not?",
      win: "That was fun, I haven't felt that much excitement in ages.",
      lose: "I guess that's why nobody challenges me.",
    },
    position: 250,
    heightPercentage: 30,
    level: 10,
    baseStatsModifiers: {
      firepower: 4,
      resilience: 2,
      speed: 0,
      precision: 3,
      energy: 1,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 3,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, PRECISION, ENERGY, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "firebrand",
        quantity: 1,
      },
      gold: {
        slug: "heatseeker",
        quantity: 1,
      },
      silver: {
        slug: "lock_sight",
        quantity: 1,
      },
      bronze: {
        slug: "ion_barrier",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "firebrand",
      shields: "ion_barrier",
      thrusters: null,
      targetingSystem: "lock_sight",
      reactorCore: "zero_core",
    },
    partPositions: {
      weapons: { x: 52, y: 57.75 },
      targeting: { x: 61.5, y: 57.75 },
      thrusters: { x: 24.2, y: 52.25 },
    },
    skills: ["systemsDecay", "phantomStrike"],
  },
  // BISHOP - STRAYLIGHT ENCLAVE
  rincon: {
    name: "Rincon",
    images: {
      ship: rinconShipImage,
      pilot: rinconPilotImage,
    },
    dialogs: {
      opening:
        "Welcome to the Straylight headquarters. I might be the lowest ranked one here, but don't underestimate me.",
      win: "Seems like you estimated me just about right.",
      lose: "I told you, you should've over...estimated me.",
    },
    position: 60,
    heightPercentage: 30,
    level: 11,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "small_scope",
        quantity: 1,
      },
      gold: {
        slug: "quick_core",
        quantity: 1,
      },
      silver: {
        slug: "major_repair_kit",
        quantity: 1,
      },
      bronze: {
        slug: "major_restore_module",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "light_laser",
      shields: "shimmer_shield",
      thrusters: "light_thruster",
      targetingSystem: "small_scope",
      reactorCore: "quick_core",
    },
    partPositions: {
      weapons: { x: 64, y: 56.25 },
      targeting: { x: 74.5, y: 56.25 },
      thrusters: { x: 27.5, y: 50.75 },
    },
    skills: [],
  },
  hadron: {
    name: "Hadron",
    images: {
      ship: hadronShipImage,
      pilot: hadronPilotImage,
    },
    dialogs: {
      opening:
        "I guess have enough ...energy... to fight you. Get it? Because my ship has a lot of antimatter energy.",
      win: "I should stop working on puns and work more on my ship.",
      lose: "You didn't get it.",
    },
    position: 90,
    heightPercentage: 30,
    level: 12,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 2,
      speed: 1,
      precision: 0,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, SPEED, FIREPOWER, PRECISION, RESILIENCE],
    isBoss: false,
    drops: {
      platinum: {
        slug: "aether_nexus",
        quantity: 1,
      },
      gold: {
        slug: "velo_nexus",
        quantity: 1,
      },
      silver: {
        slug: "star_beam",
        quantity: 1,
      },
      bronze: {
        slug: "ion_barrier",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "star_beam",
      shields: "ion_barrier",
      thrusters: "spark_booster",
      targetingSystem: null,
      reactorCore: "aether_nexus",
    },
    partPositions: {
      weapons: { x: 71, y: 56.75 },
      targeting: { x: 82, y: 56.75 },
      thrusters: { x: 21.5, y: 50 },
    },
    skills: ["energyBlast", "systemsDecay", "shieldBypass"],
  },
  acela: {
    name: "Acela",
    images: {
      ship: acelaShipImage,
      pilot: acelaPilotImage,
    },
    dialogs: {
      opening:
        "They call me: Death by a Thousand Laser... Cuts. We're workshopping it.",
      win: "I guess my new nickname should be: I Suck. I'm not good at nicknames.",
      lose: "Okay that's done, now I can go back to workshopping nicknames. You got any ideas?",
    },
    position: 120,
    heightPercentage: 30,
    level: 13,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 1,
      speed: 4,
      precision: 3,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 1,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [SPEED, ENERGY, PRECISION, RESILIENCE, FIREPOWER],
    isBoss: false,
    drops: {
      platinum: {
        slug: "novaburner",
        quantity: 1,
      },
      gold: {
        slug: "hyperburner",
        quantity: 1,
      },
      silver: {
        slug: "fader_grid",
        quantity: 1,
      },
      bronze: {
        slug: "light_laser",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "light_laser",
      shields: "glimmer_shield",
      thrusters: "novaburner",
      targetingSystem: "fader_grid",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 63, y: 55.25 },
      targeting: { x: 73, y: 55.25 },
      thrusters: { x: 29, y: 50.25 },
    },
    skills: ["rapidFire", "phantomStrike"],
  },
  megama: {
    name: "Megama",
    images: {
      ship: megamaShipImage,
      pilot: megamaPilotImage,
    },
    dialogs: {
      opening:
        "Can your weapons even pierce my dope as hell shields? You're welcome to try.",
      win: "I guess I need to upgrade my shields.",
      lose: "I tried to warn you about my shields. I'm pretty into my shields as you can tell.",
    },
    position: 150,
    heightPercentage: 30,
    level: 14,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 4,
      speed: 3,
      precision: 0,
      energy: 1,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 1,
      speed: 2,
      precision: 3,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, ENERGY, SPEED, PRECISION],
    isBoss: false,
    drops: {
      platinum: {
        slug: "light_sentinel",
        quantity: 1,
      },
      gold: {
        slug: "force_sanctuary",
        quantity: 1,
      },
      silver: {
        slug: "jetsetter",
        quantity: 1,
      },
      bronze: {
        slug: "light_laser",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "light_laser",
      shields: "light_sentinel",
      thrusters: "jetsetter",
      targetingSystem: null,
      reactorCore: "zero_core",
    },
    partPositions: {
      weapons: { x: 80, y: 59.75 },
      targeting: { x: 91, y: 59.75 },
      thrusters: { x: 13, y: 50.75 },
    },
    skills: ["shieldRestore", "emergencyRepairs"],
  },
  breeden: {
    name: "Breeden",
    images: {
      ship: breedenShipImage,
      pilot: breedenPilotImage,
    },
    dialogs: {
      opening: "Precision is the best attribute a pilot can have.",
      win: "Maybe being precise isn't all that useful. Oh my god, my identity.",
      lose: "Precisely.",
    },
    position: 180,
    heightPercentage: 30,
    level: 16,
    baseStatsModifiers: {
      firepower: 1,
      resilience: 3,
      speed: 2,
      precision: 4,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 1,
      energy: 3,
    },
    baseStatsTargets: [SPEED, FIREPOWER, PRECISION, RESILIENCE, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "stellar_sight",
        quantity: 1,
      },
      gold: {
        slug: "needle_grid",
        quantity: 1,
      },
      silver: {
        slug: "bulwark",
        quantity: 1,
      },
      bronze: {
        slug: "flash_rocket",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "glance_laser",
      shields: "bulwark",
      thrusters: "flash_rocket",
      targetingSystem: "stellar_sight",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 71, y: 58.5 },
      targeting: { x: 81.5, y: 58.5 },
      thrusters: { x: 19.5, y: 51.25 },
    },
    skills: ["pressurePoint", "eagleEye"],
  },
  revin: {
    name: "Revin",
    images: {
      ship: revinShipImage,
      pilot: revinPilotImage,
    },
    dialogs: {
      opening:
        "Sure, let's fight, I've got 5 seconds to spare. Oh, sorry, that might've come off rude.",
      win: "Oh wow, that was unexpected! ...Again, no offense intended.",
      lose: "Hey, you tried and that's ...something. I promise I'm not trying to be condescending.",
    },
    position: 210,
    heightPercentage: 30,
    level: 18,
    baseStatsModifiers: {
      firepower: 4,
      resilience: 2,
      speed: 1,
      precision: 0,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "star_breaker",
        quantity: 1,
      },
      gold: {
        slug: "moon_shot",
        quantity: 1,
      },
      silver: {
        slug: "glow_reactor",
        quantity: 1,
      },
      bronze: {
        slug: "energy_barrier",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "star_breaker",
      shields: "energy_barrier",
      thrusters: "spark_booster",
      targetingSystem: null,
      reactorCore: "glow_reactor",
    },
    partPositions: {
      weapons: { x: 49, y: 55.75 },
      targeting: { x: 67, y: 55.75 },
      thrusters: { x: 23, y: 49 },
    },
    skills: ["scattershot", "powerSurge"],
  },
  valera: {
    name: "Valera",
    images: {
      ship: valeraShipImage,
      pilot: valeraPilotImage,
    },
    dialogs: {
      opening: "You want to fight ...me? Uh, good luck, I guess.",
      win: "Well, damn.",
      lose: "lol",
    },
    position: 250,
    heightPercentage: 30,
    level: 20,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 2,
      speed: 3,
      precision: 1,
      energy: 1,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, PRECISION, ENERGY, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "quantum_blade",
        quantity: 1,
      },
      gold: {
        slug: "void_drive",
        quantity: 1,
      },
      silver: {
        slug: "vision_wall",
        quantity: 1,
      },
      bronze: {
        slug: "photon_sight",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "quantum_blade",
      shields: "vision_wall",
      thrusters: "void_drive",
      targetingSystem: "photon_sight",
      reactorCore: "zero_core",
    },
    partPositions: {
      weapons: { x: 53, y: 58.5 },
      targeting: { x: 63.5, y: 58.5 },
      thrusters: { x: 20.5, y: 51.75 },
    },
    skills: ["phantomStrike", "shieldBreaker", "emergencyRepairs"],
  },
  // SENECA - SANCTUM
  zero: {
    name: "Zero",
    images: {
      ship: zeroShipImage,
      pilot: zeroPilotImage,
    },
    dialogs: {
      opening:
        "I'm not obssessed with our planet's Antimatter Core. I'm just hopelessly in love with it.",
      win: "...I'm going to go stare at the Core for comfort.",
      lose: "Did the Core see me win!? ...Er, I know it's not sentient.",
    },
    position: 70,
    heightPercentage: 30,
    level: 21,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 1,
      speed: 0,
      precision: 3,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 3,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, FIREPOWER, RESILIENCE, PRECISION, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "hyper_reactor",
        quantity: 1,
      },
      gold: {
        slug: "mega_reactor",
        quantity: 1,
      },
      silver: {
        slug: "zephyr_core",
        quantity: 1,
      },
      bronze: {
        slug: "vapor_core",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: "energy_scope",
      reactorCore: "hyper_reactor",
    },
    partPositions: {
      weapons: { x: 59, y: 58.9 },
      targeting: { x: 69, y: 58.9 },
      thrusters: { x: 25.75, y: 53.25 },
    },
    skills: ["shieldBypass", "energyBlast", "reactorOverload"],
  },
  aven: {
    name: "Aven",
    images: {
      ship: avenShipImage,
      pilot: avenPilotImage,
    },
    dialogs: {
      opening:
        "I think energy mixes well with precision. I need to be able to actually hit you to win.",
      win: "Maybe I should mix energy with something else...",
      lose: "I told you, energy mixes with precision like ...energy mixes with other things. We don't talk about much else besides energy here on Seneca.",
    },
    position: 100,
    heightPercentage: 30,
    level: 23,
    baseStatsModifiers: {
      firepower: 1,
      resilience: 2,
      speed: 1,
      precision: 3,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 3,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [FIREPOWER, ENERGY, RESILIENCE, PRECISION, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "lock_core",
        quantity: 1,
      },
      gold: {
        slug: "sharp_core",
        quantity: 1,
      },
      silver: {
        slug: "void_scope",
        quantity: 1,
      },
      bronze: {
        slug: "void_wall",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "void_wall",
      thrusters: null,
      targetingSystem: "void_scope",
      reactorCore: "lock_core",
    },
    partPositions: {
      weapons: { x: 70.5, y: 57.25 },
      targeting: { x: 80.5, y: 57.25 },
      thrusters: { x: 20.25, y: 50.25 },
    },
    skills: ["energyBlast", "skillsRecharge", "systemsDecay"],
  },
  spectra: {
    name: "Spectra",
    images: {
      ship: spectraShipImage,
      pilot: spectraPilotImage,
    },
    dialogs: {
      opening:
        "I love my weapons, almost as I love the Core. ...Don't tell the Core.",
      win: "I now hate my weapons. Stupid weapons.",
      lose: "I now love my weapons even more. Lovely weapons.",
    },
    position: 130,
    heightPercentage: 30,
    level: 25,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 1,
      speed: 1,
      precision: 2,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 3,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [SPEED, ENERGY, RESILIENCE, PRECISION, FIREPOWER],
    isBoss: false,
    drops: {
      platinum: {
        slug: "striker_nexus",
        quantity: 1,
      },
      gold: {
        slug: "force_nexus",
        quantity: 1,
      },
      silver: {
        slug: "void_cutter",
        quantity: 1,
      },
      bronze: {
        slug: "energy_scope",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "void_cutter",
      shields: null,
      thrusters: null,
      targetingSystem: "energy_scope",
      reactorCore: "striker_nexus",
    },
    partPositions: {
      weapons: { x: 58, y: 61 },
      targeting: { x: 68, y: 61 },
      thrusters: { x: 20, y: 55.5 },
    },
    skills: ["shieldBreaker", "energyBlast", "scattershot"],
  },
  radien: {
    name: "Radien",
    images: {
      ship: radienShipImage,
      pilot: radienPilotImage,
    },
    dialogs: {
      opening:
        "Let's get this over quickly. Quickly because I'm fast. Faster than you.",
      win: "Well, I guess I'm too slow. I need to process this. Slowly.",
      lose: "That went even quicker than I expected.",
    },
    position: 160,
    heightPercentage: 30,
    level: 27,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 1,
      speed: 3,
      precision: 1,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 1,
    },
    baseStatsTargets: [RESILIENCE, ENERGY, FIREPOWER, PRECISION, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "flash_nexus",
        quantity: 1,
      },
      gold: {
        slug: "swift_nexus",
        quantity: 1,
      },
      silver: {
        slug: "voidburner",
        quantity: 1,
      },
      bronze: {
        slug: "energy_beam",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "energy_beam",
      shields: null,
      thrusters: "voidburner",
      targetingSystem: null,
      reactorCore: "flash_nexus",
    },
    partPositions: {
      weapons: { x: 56, y: 56.5 },
      targeting: { x: 66, y: 56.5 },
      thrusters: { x: 33, y: 51.5 },
    },
    skills: ["rapidFire", "phantomStrike", "energyBlast"],
  },
  graver: {
    name: "Graver",
    images: {
      ship: graverShipImage,
      pilot: graverPilotImage,
    },
    dialogs: {
      opening:
        "The Core is pretty cool, but I bet it wishes it had more shielding, like me.",
      win: "I didn't like that at all.",
      lose: "Why did you even try, have you SEEN my ship?",
    },
    position: 200,
    heightPercentage: 30,
    level: 28,
    baseStatsModifiers: {
      firepower: 1,
      resilience: 3,
      speed: 2,
      precision: 1,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 1,
    },
    baseStatsTargets: [PRECISION, ENERGY, FIREPOWER, RESILIENCE, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "fortress_core",
        quantity: 1,
      },
      gold: {
        slug: "castle_core",
        quantity: 1,
      },
      silver: {
        slug: "void_guard",
        quantity: 1,
      },
      bronze: {
        slug: "nexus_rocket",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "void_guard",
      thrusters: "nexus_rocket",
      targetingSystem: null,
      reactorCore: "fortress_core",
    },
    partPositions: {
      weapons: { x: 66, y: 64.5 },
      targeting: { x: 76, y: 64.5 },
      thrusters: { x: 12.5, y: 54 },
    },
    skills: ["energyBlast", "shieldRestore", "emergencyRepairs"],
  },
  wraeth: {
    name: "Wraeth",
    images: {
      ship: wraethShipImage,
      pilot: wraethPilotImage,
    },
    dialogs: {
      opening: "May Core be with you.",
      win: "Oh my Core... I didn't expect that.",
      lose: "Core, I'm good.",
    },
    position: 250,
    heightPercentage: 30,
    level: 30,
    baseStatsModifiers: {
      firepower: 0,
      resilience: 1,
      speed: 2,
      precision: 3,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 3,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, PRECISION, FIREPOWER, RESILIENCE, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "hypernova",
        quantity: 1,
      },
      gold: {
        slug: "supernova",
        quantity: 1,
      },
      silver: {
        slug: "star_nexus",
        quantity: 1,
      },
      bronze: {
        slug: "moon_nexus",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: "nexus_rocket",
      targetingSystem: "void_scope",
      reactorCore: "hypernova",
    },
    partPositions: {
      weapons: { x: 63, y: 66 },
      targeting: { x: 73, y: 66 },
      thrusters: { x: 25.8, y: 59 },
    },
    skills: ["energyBlast", "shieldBypass", "systemsDecay", "reactorOverload"],
  },
  // SENECA - UNDERHAVEN
  arc: {
    name: "Arc",
    images: {
      ship: arcShipImage,
      pilot: arcPilotImage,
    },
    dialogs: {
      opening:
        "Antimatter is pretty cool. I tried eating it once, it didn't go well.",
      win: "Well dang, I lost. Maybe I should've eaten more antimatter.",
      lose: "Aha, I win! Maybe I should eat more antimatter.",
    },
    position: 70,
    heightPercentage: 35,
    level: 32,
    baseStatsModifiers: {
      firepower: 0,
      resilience: 3,
      speed: 1,
      precision: 2,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 3,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, FIREPOWER, RESILIENCE, PRECISION, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "fury_reactor",
        quantity: 1,
      },
      gold: {
        slug: "alpha_reactor",
        quantity: 1,
      },
      silver: {
        slug: "mega_core",
        quantity: 1,
      },
      bronze: {
        slug: "surge_core",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "null_shield",
      thrusters: null,
      targetingSystem: null,
      reactorCore: "fury_reactor",
    },
    partPositions: {
      weapons: { x: 59.5, y: 60 },
      targeting: { x: 69.5, y: 60 },
      thrusters: { x: 17.75, y: 53.25 },
    },
    skills: ["systemsDecay", "energyBlast", "reactorOverload"],
  },
  vexen: {
    name: "Vexen",
    images: {
      ship: vexenShipImage,
      pilot: vexenPilotImage,
    },
    dialogs: {
      opening: "Wanna fight?",
      win: "I shouldn't have asked.",
      lose: "Wanna fight again?",
    },
    position: 100,
    heightPercentage: 35,
    level: 34,
    baseStatsModifiers: {
      firepower: 1,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 3,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [RESILIENCE, ENERGY, FIREPOWER, PRECISION, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "burst_matrix",
        quantity: 1,
      },
      gold: {
        slug: "dash_matrix",
        quantity: 1,
      },
      silver: {
        slug: "nova_drive",
        quantity: 1,
      },
      bronze: {
        slug: "flux_scope",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: "nova_drive",
      targetingSystem: "flux_scope",
      reactorCore: "burst_matrix",
    },
    partPositions: {
      weapons: { x: 59, y: 58 },
      targeting: { x: 69, y: 58 },
      thrusters: { x: 30, y: 52.25 },
    },
    skills: ["rapidFire", "phantomStrike", "energyBlast"],
  },
  behemal: {
    name: "Behemal",
    images: {
      ship: behemalShipImage,
      pilot: behemalPilotImage,
    },
    dialogs: {
      opening: "Dude, I'm huge. You really wanna do this?",
      win: "Time to bulk up my ship again.",
      lose: "Do you even lift... antimatter into your ship?",
    },
    position: 130,
    heightPercentage: 35,
    level: 36,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 3,
      speed: 1,
      precision: 1,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 3,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [PRECISION, ENERGY, FIREPOWER, RESILIENCE, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "aegis_core",
        quantity: 1,
      },
      gold: {
        slug: "ward_nexus",
        quantity: 1,
      },
      silver: {
        slug: "null_barrier",
        quantity: 1,
      },
      bronze: {
        slug: "null_blade",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "null_blade",
      shields: "null_barrier",
      thrusters: null,
      targetingSystem: null,
      reactorCore: "aegis_core",
    },
    partPositions: {
      weapons: { x: 68.25, y: 66 },
      targeting: { x: 78.25, y: 66 },
      thrusters: { x: 16, y: 56 },
    },
    skills: ["energyBlast", "shieldRestore", "emergencyRepairs"],
  },
  tracer: {
    name: "Tracer",
    images: {
      ship: tracerShipImage,
      pilot: tracerPilotImage,
    },
    dialogs: {
      opening: "I've been told I'm a very accurate shot.",
      win: "They were lying to me!?.",
      lose: "More people should tell me I'm accurate. I like compliments.",
    },
    position: 160,
    heightPercentage: 35,
    level: 38,
    baseStatsModifiers: {
      firepower: 1,
      resilience: 1,
      speed: 2,
      precision: 3,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 3,
      speed: 2,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [FIREPOWER, ENERGY, RESILIENCE, PRECISION, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "tracer_matrix",
        quantity: 1,
      },
      gold: {
        slug: "sight_matrix",
        quantity: 1,
      },
      silver: {
        slug: "phaser_sight",
        quantity: 1,
      },
      bronze: {
        slug: "plasma_drive",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: "plasma_drive",
      targetingSystem: "phaser_sight",
      reactorCore: "tracer_matrix",
    },
    partPositions: {
      weapons: { x: 69.75, y: 58.85 },
      targeting: { x: 79.75, y: 58.85 },
      thrusters: { x: 19.25, y: 51.5 },
    },
    skills: ["eagleEye", "energyBlast", "systemsDecay"],
  },
  volta: {
    name: "Volta",
    images: {
      ship: voltaShipImage,
      pilot: voltaPilotImage,
    },
    dialogs: {
      opening: "Firepower and energy is the way to go. My two passions.",
      win: "I have no passions now.",
      lose: `I'm going to coin the term "firepowergy". Do you like it?`,
    },
    position: 200,
    heightPercentage: 35,
    level: 39,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 2,
      speed: 1,
      precision: 1,
      energy: 3,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 1,
    },
    baseStatsTargets: [SPEED, ENERGY, RESILIENCE, PRECISION, FIREPOWER],
    isBoss: false,
    drops: {
      platinum: {
        slug: "ravager_core",
        quantity: 1,
      },
      gold: {
        slug: "reaper_core",
        quantity: 1,
      },
      silver: {
        slug: "void_blade",
        quantity: 1,
      },
      bronze: {
        slug: "null_shield",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "void_blade",
      shields: "null_shield",
      thrusters: null,
      targetingSystem: null,
      reactorCore: "ravager_core",
    },
    partPositions: {
      weapons: { x: 60, y: 65 },
      targeting: { x: 70, y: 65 },
      thrusters: { x: 15, y: 58.5 },
    },
    skills: ["shieldBreaker", "energyBlast", "scattershot"],
  },
  reaver: {
    name: "Reaver",
    images: {
      ship: reaverShipImage,
      pilot: reaverPilotImage,
    },
    dialogs: {
      opening:
        "I'm surprised you're still standing here in Underhaven. We're pretty tough here.",
      win: "Well, I guess you're tough too. Welcome to Underhaven.",
      lose: "Maybe go back to Haven. You're not ready for UNDERhaven.",
    },
    position: 250,
    heightPercentage: 35,
    level: 40,
    baseStatsModifiers: {
      firepower: 0,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 3,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, PRECISION, FIREPOWER, RESILIENCE, SPEED],
    isBoss: false,
    drops: {
      platinum: {
        slug: "omega_core",
        quantity: 1,
      },
      gold: {
        slug: "apex_core",
        quantity: 1,
      },
      silver: {
        slug: "eclipse_core",
        quantity: 1,
      },
      bronze: {
        slug: "nebula_core",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "void_wall",
      thrusters: "nova_drive",
      targetingSystem: "flux_scope",
      reactorCore: "omega_core",
    },
    partPositions: {
      weapons: { x: 63.75, y: 67.6 },
      targeting: { x: 73.75, y: 67.6 },
      thrusters: { x: 25.5, y: 60.75 },
    },
    skills: ["energyBlast", "shieldBypass", "systemsDecay", "reactorOverload"],
  },
};
