import styled, { css } from "styled-components";

import BattleRatingSmall from "components/base/BattleRatingSmall";
import ItemIcon from "components/base/ItemIcon";
import Skill from "components/base/Skill";
import Tooltip from "components/base/Tooltip";
import TooltipStats from "components/base/TooltipStats";
import { AREAS, PLANETS } from "data/areas";
import { BASE_STATS_INFO } from "data/baseStats";
import { BATTLE_RATINGS } from "data/battleRatings";
import { MOBS } from "data/mobs";
import { PARTS } from "data/parts";
import { PILOTS } from "data/pilots";
import { UPGRADES } from "data/upgrades";
import { getUpgradeData, isSupplyItem } from "libs/item";
import { getOpponentSkills, getSkillData } from "libs/skill";
import {
  calculateInitialMobStats,
  getBaseStatData,
  getPartData,
} from "libs/stats";
import { useSelector } from "react-redux";
import { getCharacter, OPPONENT_FIGHTER_ACTIONS } from "redux/selectors";
import {
  BattleRatings,
  CharacterBaseStats,
  MobDropModel,
  MobModel,
  PartModel,
  PartTypes,
  PilotModel,
  Planets,
  ShopModel,
  SkillModel,
  UpgradeModel,
} from "types";
import { COLORS } from "utils/constants";
import { SHOPS } from "data/shops";

interface DataViewProps {
  planetFilter: Planets | null;
}

interface FilterProps {
  isActive: boolean;
}

interface ColorProps {
  color: string;
}

interface IconProps {
  image: string;
  color: string;
}

interface SkillNameProps {
  isDefault: boolean;
}

const DataViewWrapper = styled.div`
  width: 100vw;
  background-color: black;
  color: white;
  padding-top: 30px;
`;

const FiltersWrapper = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  height: 50px;
  width: 100%;
  background-color: #000000;
  display: flex;
  gap: 10px;
  padding: 0 20px;
  align-items: center;
`;

const activeMixin = css`
  background-color: #ffffff;
  pointer-events: none;
`;

const Filter = styled.a<FilterProps>`
  height: 30px;
  text-transform: uppercase;
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center;
  padding: 0 20px;
  text-decoration: none;
  background-color: #777777;
  border-radius: 4px;

  :hover {
    background-color: #999999;
  }

  ${(props) => props.isActive && activeMixin}
`;

const SectionWrapper = styled.div`
  margin: 20px;
  background-color: #ffffff10;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 24px;
  padding: 8px;
  background-color: #333333;
  border-radius: 4px;
  position: sticky;
  top: 50px;
  z-index: 2;
`;

const MobImages = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const MobImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  background-color: #00000020;
  border: 1px #00000020 solid;
  border-radius: 4px;
`;

const MobShip = styled.img`
  transform: scale(1.1);
  aspect-ratio: 1 / 1;
`;

const MobPilot = styled.img`
  aspect-ratio: 1 / 1;
`;

const SectionName = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

const MobLevel = styled.div`
  color: ${COLORS.LEVELS};
`;

const SectionContent = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
`;

const Group = styled.div`
  background-color: #ffffff10;
  border-radius: 4px;
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
`;

const GroupTitle = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  background-color: #ffffff10;
  padding: 10px;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px;
`;

const Stat = styled.div<ColorProps>`
  color: ${(props) => props.color};
  text-transform: uppercase;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  flex-grow: 1;
`;

const Part = styled.div<ColorProps>`
  color: ${(props) => props.color};
  display flex;
  gap: 10px;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
`;

const ItemIconWrapper = styled.div`
  height: 30px;
  min-width: 30px;
  flex-basis: 30px;
`;

const UpgradeReqs = styled.div`
  display: flex;
  justify-content: end;
  gap: 4px;
`;

const UpgradeReq = styled.div<ColorProps>`
  color: ${(props) => props.color};
  font-weight: 600;
  z-index: 1;
  text-align: right;
`;

const EmptyItem = styled.div`
  height: 30px;
  width: 30px;
  background-color: #ffffff10;
  border-radius: 4px;
  flex-basis: 30px;
`;

const Icon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${(props) => props.color};
`;

// All Upgrades List

const UpgradesTitle = styled(SectionTitle)`
  padding: 10px;
`;

const UpgradeRows = styled(Rows)`
  gap: 8px;
`;

const UpgradeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const UpgradeMain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UpgradeIconWrapper = styled.div`
  width: 44px;
  min-width: 44px;
  height: 44px;
`;

const UpgradeName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
`;

// All Skills List

const SkillsTitle = styled(SectionTitle)`
  padding: 10px;
`;

const SkillRows = styled(Rows)`
  gap: 8px;
`;

const SkillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const SkillMain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SkillIconWrapper = styled.div`
  width: 45px;
  height: 45px;
`;

const SkillName = styled.div<SkillNameProps>`
  font-weight: 600;
  text-transform: uppercase;
  ${(props) =>
    props.isDefault &&
    `  color: ${COLORS.UNLOCKED};
`};
`;

const SkillCost = styled.div`
  color: ${COLORS.ENHANCEMENTS};
  font-weight: 600;
  z-index: 1;
  text-align: right;
`;

export default function DataView({ planetFilter }: DataViewProps) {
  const {
    data: { fighterData: characterFighter },
  } = useSelector(getCharacter);

  // Filter mobs by planet
  const filteredMobs = Object.entries(MOBS).filter(([key, value]) => {
    const mobSlug = key as string;
    if (planetFilter) {
      const planetAreas = Object.values(AREAS).filter((areaInfo) => {
        return areaInfo.planet === planetFilter;
      });
      const planetMobs = planetAreas.reduce((planetMobs, areaInfo) => {
        const areaMobs = areaInfo.mobs as string[];
        return [...planetMobs, ...areaMobs];
      }, [] as string[]);
      // Check if mob is part of planet
      return planetMobs.includes(mobSlug);
    } else {
      return true;
    }
  });

  // Filter upgrades by planet
  const filteredUpgrades = Object.entries(UPGRADES).filter(([key, value]) => {
    const upgradeSlug = key as string;
    if (planetFilter) {
      // Get mob drops
      const planetMobs = Object.values(filteredMobs);
      const planetMobDropUpgrades = planetMobs.reduce(
        (mobUpgrades, [key, value]) => {
          const mobInfo = value as MobModel;
          const mobDrops = Object.values(mobInfo.drops).reduce(
            (mobDrops, ratingInfo: MobDropModel) => {
              const isUpgrade = !isSupplyItem(ratingInfo.slug);
              if (isUpgrade) {
                return [...mobDrops, ratingInfo.slug];
              } else {
                // Skip supplies
                return mobDrops;
              }
            },
            [] as string[]
          );
          return [...mobUpgrades, ...mobDrops];
        },
        [] as string[]
      );

      // Get mob installed upgrades
      const planetMobInstalledUpgrades = planetMobs.reduce(
        (mobUpgrades, [key, value]) => {
          const mobInfo = value as MobModel;
          const mobInstalledUpgrades = Object.values(
            mobInfo.installedUpgrades
          ).filter((upgrade) => !!upgrade);
          return [...mobUpgrades, ...mobInstalledUpgrades];
        },
        [] as string[]
      );

      // get shop upgrades
      const planetShops = Object.values(SHOPS).filter((shopInfo) => {
        return shopInfo.planet === planetFilter;
      });
      const planetShopUpgrades = planetShops.reduce(
        (shopUpgrades, shopInfo: ShopModel) => {
          if (shopInfo.upgrades) {
            return [...shopUpgrades, ...shopInfo.upgrades];
          } else {
            return shopUpgrades;
          }
        },
        [] as string[]
      );

      // Combine mob and shop upgrades, dedupe upgrades
      const planetUpgrades = Array.from(
        new Set([
          ...planetMobDropUpgrades,
          ...planetMobInstalledUpgrades,
          ...planetShopUpgrades,
        ])
      );

      // Check if upgrade is part of planet mob
      return planetUpgrades.includes(upgradeSlug);
    } else {
      return true;
    }
  });

  return (
    <DataViewWrapper>
      <FiltersWrapper>
        <Filter href={`?dataview=on`} isActive={!planetFilter}>
          All
        </Filter>
        {Object.values(PLANETS).map((planetInfo) => (
          <Filter
            href={`?dataview=on&planet=${planetInfo.slug}`}
            isActive={planetInfo.slug === planetFilter}
          >
            {planetInfo.name}
          </Filter>
        ))}
      </FiltersWrapper>
      {filteredMobs.map(([key, value]) => {
        const mobSlug = key as string;
        const { name, images, level, drops, installedUpgrades, skills } =
          value as MobModel;

        const { totalBaseStats, derivedStats } =
          calculateInitialMobStats(mobSlug);

        const mobSkills = getOpponentSkills(
          skills,
          derivedStats.total.complete.fasterRecharge
        );

        const mobFighter = {
          name,
          data: {
            stats: derivedStats.current.complete,
            currentBaseStats: totalBaseStats,
            totalBaseStats,
            skills: [],
            skillsRecharge: {},
            skillsValues: {},
            damage: [],
            turnPriority: 0,
          },
          actions: OPPONENT_FIGHTER_ACTIONS,
        };

        return (
          <SectionWrapper>
            <SectionTitle>
              <MobImages>
                <MobImageWrapper>
                  <MobShip src={images.ship} />
                </MobImageWrapper>
                <MobImageWrapper>
                  <MobPilot src={images.pilot} />
                </MobImageWrapper>
              </MobImages>
              <SectionName>{name}</SectionName>
              <MobLevel>✧{level}</MobLevel>
            </SectionTitle>
            <SectionContent>
              <Group>
                <GroupTitle>Base Stats</GroupTitle>
                <Rows>
                  {Object.values(BASE_STATS_INFO).map((baseStatInfo) => {
                    const baseStatSlug =
                      baseStatInfo.slug as keyof CharacterBaseStats;

                    return (
                      <Tooltip
                        key={baseStatSlug}
                        content={
                          <TooltipStats
                            baseStat={baseStatSlug}
                            derivedStats={derivedStats.total.complete}
                          />
                        }
                      >
                        <Row>
                          <Part color={baseStatInfo.color}>
                            <Icon
                              image={baseStatInfo.image}
                              color={baseStatInfo.color}
                            />
                            {baseStatInfo.name}
                          </Part>
                          <Stat color={baseStatInfo.color}>
                            {totalBaseStats[baseStatSlug]}
                          </Stat>
                        </Row>
                      </Tooltip>
                    );
                  })}
                </Rows>
              </Group>
              <Group>
                <GroupTitle>Installed Upgrades</GroupTitle>
                <Rows>
                  {Object.entries(installedUpgrades).map(([key, value]) => {
                    const partType = key as PartTypes;
                    const upgradeSlug = value as string;
                    const partInfo = getPartData(partType);
                    const upgradeInfo =
                      !!upgradeSlug && getUpgradeData(upgradeSlug);

                    return (
                      <Row key={partType}>
                        <Part color={partInfo.color}>
                          <Icon image={partInfo.image} color={partInfo.color} />
                          {partInfo.name}
                        </Part>
                        {!!upgradeInfo ? (
                          <ItemInfo>
                            <UpgradeReqs>
                              {Object.entries(upgradeInfo.requirements).map(
                                ([key, value]) => {
                                  const baseStat =
                                    key as keyof CharacterBaseStats;
                                  const reqValue = value as number;
                                  const baseStatInfo =
                                    getBaseStatData(baseStat);

                                  return (
                                    <UpgradeReq
                                      key={baseStat}
                                      color={baseStatInfo.color}
                                    >
                                      {reqValue}
                                    </UpgradeReq>
                                  );
                                }
                              )}
                            </UpgradeReqs>
                            <ItemIconWrapper>
                              <ItemIcon slug={upgradeSlug} />
                            </ItemIconWrapper>
                          </ItemInfo>
                        ) : (
                          <ItemInfo>
                            <UpgradeReqs />
                            <EmptyItem />
                          </ItemInfo>
                        )}
                      </Row>
                    );
                  })}
                </Rows>
              </Group>
              <Group>
                <GroupTitle>Drops</GroupTitle>
                <Rows>
                  {Object.keys(BATTLE_RATINGS).map((key) => {
                    const battleRating = key as BattleRatings;
                    const drop = drops[battleRating];

                    const isUpgrade = !!drop && !isSupplyItem(drop.slug);
                    const upgradeInfo = isUpgrade && getUpgradeData(drop.slug);

                    return (
                      <Row key={key}>
                        <BattleRatingSmall rating={battleRating} iconLeft />
                        {!!drop ? (
                          <ItemInfo>
                            <UpgradeReqs>
                              {!!upgradeInfo &&
                                Object.entries(upgradeInfo.requirements).map(
                                  ([key, value]) => {
                                    const baseStat =
                                      key as keyof CharacterBaseStats;
                                    const reqValue = value as number;
                                    const baseStatInfo =
                                      getBaseStatData(baseStat);

                                    return (
                                      <UpgradeReq
                                        key={baseStat}
                                        color={baseStatInfo.color}
                                      >
                                        {reqValue}
                                      </UpgradeReq>
                                    );
                                  }
                                )}
                            </UpgradeReqs>
                            <ItemIconWrapper>
                              <ItemIcon slug={drop.slug} />
                            </ItemIconWrapper>
                          </ItemInfo>
                        ) : (
                          <ItemInfo>
                            <UpgradeReqs />
                            <EmptyItem />
                          </ItemInfo>
                        )}
                      </Row>
                    );
                  })}
                </Rows>
              </Group>
              <Group>
                <GroupTitle>Skills</GroupTitle>
                <SkillRows>
                  {Object.values(mobSkills).map((value) => {
                    const skillInfo = value as SkillModel;

                    return (
                      <SkillWrapper key={skillInfo.slug}>
                        <SkillMain>
                          <SkillIconWrapper>
                            <Skill
                              skill={skillInfo}
                              attacker={mobFighter}
                              defender={characterFighter}
                              isShowValues
                            />
                          </SkillIconWrapper>
                          <SkillName isDefault={false}>
                            {skillInfo.name}
                          </SkillName>
                        </SkillMain>
                      </SkillWrapper>
                    );
                  })}
                </SkillRows>
              </Group>
            </SectionContent>
          </SectionWrapper>
        );
      })}
      <SectionWrapper>
        <UpgradesTitle>
          <SectionName>Upgrades</SectionName>
        </UpgradesTitle>
        <SectionContent>
          {Object.values(PARTS).map((value) => {
            const partInfo = value as PartModel;
            return (
              <Group key={partInfo.slug}>
                <GroupTitle>{partInfo.name}</GroupTitle>
                <UpgradeRows>
                  {filteredUpgrades
                    .filter(
                      ([_key, value]) =>
                        !value.isDefault && value.part === partInfo.slug
                    )
                    .sort(([_aKey, aValue], [_bKey, bValue]) => {
                      const aInfo = getUpgradeData(aValue.slug);
                      const bInfo = getUpgradeData(bValue.slug);
                      return (
                        aInfo.requirementsSum - bInfo.requirementsSum ||
                        aInfo.gradeData.multiplier - bInfo.gradeData.multiplier
                      );
                    })
                    .map(([_key, value]) => {
                      const upgrade = value as UpgradeModel;
                      const upgradeInfo = getUpgradeData(upgrade.slug);
                      return (
                        <UpgradeWrapper key={upgrade.slug}>
                          <UpgradeMain>
                            <UpgradeIconWrapper>
                              <ItemIcon slug={upgrade.slug} />
                            </UpgradeIconWrapper>
                            <UpgradeName>{upgradeInfo.name}</UpgradeName>
                          </UpgradeMain>
                          <UpgradeReqs>
                            {Object.entries(upgradeInfo.requirements).map(
                              ([key, value]) => {
                                const baseStat =
                                  key as keyof CharacterBaseStats;
                                const reqValue = value as number;
                                const baseStatInfo = getBaseStatData(baseStat);

                                return (
                                  <UpgradeReq
                                    key={baseStat}
                                    color={baseStatInfo.color}
                                  >
                                    {reqValue}
                                  </UpgradeReq>
                                );
                              }
                            )}
                          </UpgradeReqs>
                        </UpgradeWrapper>
                      );
                    })}
                </UpgradeRows>
              </Group>
            );
          })}
        </SectionContent>
      </SectionWrapper>
      <SectionWrapper>
        <SkillsTitle>
          <SectionName>Skills</SectionName>
        </SkillsTitle>
        <SectionContent>
          {Object.values(PILOTS).map((value) => {
            const pilotInfo = value as PilotModel;
            return (
              <Group key={pilotInfo.name}>
                <GroupTitle>{pilotInfo.name}</GroupTitle>
                <SkillRows>
                  {pilotInfo.skills.map((skill) => {
                    const skillInfo = getSkillData(skill);
                    return (
                      <SkillWrapper>
                        <SkillMain>
                          <SkillIconWrapper>
                            <Skill
                              skill={skillInfo}
                              attacker={characterFighter}
                              isShowValues
                            />
                          </SkillIconWrapper>
                          <SkillName isDefault={!!skillInfo.isDefault}>
                            {skillInfo.name}
                          </SkillName>
                        </SkillMain>
                        <SkillCost>{skillInfo.medallions}</SkillCost>
                      </SkillWrapper>
                    );
                  })}
                </SkillRows>
              </Group>
            );
          })}
        </SectionContent>
      </SectionWrapper>
    </DataViewWrapper>
  );
}
