import { PARTS } from "data/parts";
import { DEFAULT_ITEM_MAX_AMOUNT, SUPPLIES, SUPPLY_TYPES } from "data/supplies";
import { GRADES, UPGRADES } from "data/upgrades";
import {
  CharacterBaseStats,
  CharacterDerivedStats,
  GradeModel,
  GroupedItemsData,
  InstalledUpgradesData,
  InventoryItemModel,
  InventoryModel,
  ItemData,
  ModifiedDerivedStats,
  PartModel,
  PartTypes,
  SupplyModel,
  SupplyTypeModel,
  SupplyTypes,
  UpgradeComparisonModel,
  UpgradeModel,
} from "types";
import { roundWhole } from "utils/formatters";
import {
  getDerivedStatData,
  getPropertyData,
  getSupplyTypeData,
} from "./stats";

export const UPGRADE_BASE_CREDITS = 50;
export const RECALL_POSITION = 30;

// DATA

export const getItemData = (slug: string): ItemData => {
  const propertyInfo = getPropertyData("credits");
  const isSupply = isSupplyItem(slug);
  const itemData = isSupply ? getSupplyData(slug) : getUpgradeData(slug);

  let maxQuantity = DEFAULT_ITEM_MAX_AMOUNT;
  if (isSupply) {
    const supplyInfo = getSupplyData(slug);
    const supplyTypeInfo = getSupplyTypeData(supplyInfo.type);
    maxQuantity = supplyTypeInfo.maxQuantity;
  }

  return {
    slug: itemData.slug,
    name: itemData.name,
    description: itemData.description,
    icon: itemData.image,
    credits: itemData.credits,
    sellCredits: propertyInfo.rounder(itemData.credits / 2),
    maxQuantity,
  };
};

export const isSupplyItem = (slug: string): boolean => {
  if (!!SUPPLIES[slug]) {
    return true;
  }
  return false;
};

export const getSupplyData = (slug: string): SupplyModel => {
  return SUPPLIES[slug];
};

export const getUpgradeData = (slug: string) => {
  const upgradeData = UPGRADES[slug];
  if (!upgradeData) {
    console.error(slug);
  }
  const gradeData = getGradeData(upgradeData.grade);

  // Calculate stats for upgrade
  const derivedStats = calculateUpgradeStats(upgradeData, gradeData);

  // Calculate credits for upgrade
  const credits = upgradeData.isTutorial
    ? 0
    : calculateUpgradeCredits(upgradeData, gradeData);

  // Calculate credits for upgrade
  const requirementsSum = calculateRequirementsSum(upgradeData);

  return {
    ...upgradeData,
    gradeData,
    derivedStats,
    credits,
    requirementsSum,
  };
};

export const getGradeData = (slug: string) => {
  return GRADES[slug];
};

// UPGRADE COMPARISONS

export const getUpgradeComparisonStats = (
  newUpgradeStats: ModifiedDerivedStats,
  installedUpgradeStats: ModifiedDerivedStats = {}
) => {
  const combinedStats = Object.keys({
    ...newUpgradeStats,
    ...installedUpgradeStats,
  });

  const statDiffs = combinedStats.reduce((acc, key) => {
    const derivedStat = key as keyof CharacterDerivedStats;

    const newStatValue = newUpgradeStats[derivedStat] || 0;
    const installedStatValue = installedUpgradeStats[derivedStat] || 0;
    const difference = newStatValue - installedStatValue;

    acc[derivedStat] = {
      difference,
      value: newStatValue,
    };
    return acc;
  }, {} as Record<keyof CharacterDerivedStats, UpgradeComparisonModel>);

  return statDiffs;
};

export const getComparisonIndicator = (difference: number) => {
  if (difference > 0) {
    return "⇧";
  } else if (difference < 0) {
    return "⇩";
  } else {
    return null;
  }
};

// SHOP & INVENTORY

export const getFilteredUpgrades = (
  upgrades: string[],
  statFilter: keyof CharacterDerivedStats | null
): string[] => {
  if (!!statFilter) {
    return upgrades.filter((upgrade) => {
      const { derivedStats } = getUpgradeData(upgrade);
      return !!derivedStats[statFilter];
    });
  }
  return upgrades;
};

export const getGroupedSupplies = (supplies: string[]): GroupedItemsData => {
  const groupedSupplies = Object.entries(SUPPLY_TYPES).reduce(
    (acc, [key, value]) => {
      const supplyType = key as SupplyTypes;
      const supplyTypeData = value as SupplyTypeModel;
      const { name } = supplyTypeData;
      return {
        ...acc,
        [supplyType]: {
          name,
          items: [],
        },
      };
    },
    {} as GroupedItemsData
  );

  // Group supplies by their type property
  supplies.forEach((supply) => {
    const { type } = getSupplyData(supply);
    groupedSupplies[type].items.push(supply);
  });

  // Sort supplies within each group
  Object.keys(groupedSupplies).forEach((key) => {
    const supplyType = key as SupplyTypes;
    groupedSupplies[supplyType].items.sort((a, b) => {
      const aData = getSupplyData(a);
      const bData = getSupplyData(b);
      // Sort by payload values if they exist, otherwise use credits
      if (!!aData.payload && !!bData.payload) {
        return aData.payload - bData.payload;
      } else {
        return aData.credits - bData.credits;
      }
    });
  });

  return groupedSupplies;
};

export const getGroupedUpgrades = (upgrades: string[]): GroupedItemsData => {
  const groupedUpgrades = Object.entries(PARTS).reduce((acc, [key, value]) => {
    const partType = key as PartTypes;
    const partData = value as PartModel;
    const { name } = partData;
    return {
      ...acc,
      [partType]: {
        name,
        items: [],
      },
    };
  }, {} as GroupedItemsData);

  // Group upgrades by their part property
  upgrades.forEach((upgrade) => {
    const { part } = getUpgradeData(upgrade);
    groupedUpgrades[part].items.push(upgrade);
  });

  // Sort upgrades within each group
  Object.keys(groupedUpgrades).forEach((key) => {
    const partType = key as PartTypes;
    groupedUpgrades[partType].items.sort((a, b) => {
      const aInfo = getUpgradeData(a);
      const bInfo = getUpgradeData(b);
      // Sort by credits - takes into account reqs and grade
      return (
        aInfo.requirementsSum - bInfo.requirementsSum ||
        aInfo.gradeData.multiplier - bInfo.gradeData.multiplier
      );
    });
  });

  return groupedUpgrades;
};

export const getInventoryItemAmount = (
  slug: string,
  inventory: InventoryModel
): number => {
  let itemAmount = 0;

  const isSupply = isSupplyItem(slug);
  if (isSupply) {
    const inventorySupply = inventory.supplies.find(
      (supply: InventoryItemModel) => supply.slug === slug
    );
    if (inventorySupply) {
      itemAmount = inventorySupply.quantity;
    }
  } else {
    const inventoryUpgrade = inventory.upgrades.find(
      (upgrade: InventoryItemModel) => upgrade.slug === slug
    );
    if (inventoryUpgrade) {
      itemAmount = inventoryUpgrade.quantity;
    }
  }

  return itemAmount;
};

export const calculateUpgradeStats = (
  upgrade: UpgradeModel,
  grade: GradeModel
): ModifiedDerivedStats => {
  const { requirements } = upgrade;
  const { multiplier } = grade;

  const derivedStats: { [key: string]: number } = {};

  const statMods = upgrade.derivedStatsModifiers;
  for (const key in statMods) {
    const statType = key as keyof CharacterDerivedStats;
    const statWeight = statMods[statType] as number;
    const statInfo = getDerivedStatData(statType);

    // Pick the requirement value based on corresponding base stat of derived stat
    const reqValue = requirements[statInfo.baseStat] || 0;

    const statValue = statInfo.rounder(
      statInfo.incrementValue * statWeight * reqValue ** multiplier
    );

    derivedStats[statType] = statValue;
  }

  return derivedStats;
};

export const calculateUpgradeCredits = (
  upgrade: UpgradeModel,
  grade: GradeModel
): number => {
  const { multiplier } = grade;

  const requirementsSum = calculateRequirementsSum(upgrade);

  const credits = roundWhole(
    UPGRADE_BASE_CREDITS * requirementsSum ** multiplier
  );

  return credits;
};

// UPGRADE REQUIREMENTS

export const calculateRequirementsSum = (upgrade: UpgradeModel): number => {
  const { requirements } = upgrade;

  const requirementsSum = Object.values(requirements).reduce(
    (acc, value) => acc + value,
    0
  );

  return requirementsSum;
};

export const getInvalidUpgradeParts = (
  installedUpgrades: InstalledUpgradesData | null,
  totalBaseStats: CharacterBaseStats
): PartTypes[] => {
  const invalidParts = [] as PartTypes[];

  // Ensure that character upgrade reqs are met by base stats
  for (const partKey in installedUpgrades) {
    const part = partKey as PartTypes;
    const upgradeSlug = installedUpgrades[part];
    if (!!upgradeSlug) {
      if (!isUpgradeValid(upgradeSlug, totalBaseStats)) {
        invalidParts.push(part);
      }
    }
  }
  return invalidParts;
};

export const isUpgradeValid = (
  upgradeSlug: string,
  totalBaseStats: CharacterBaseStats
) => {
  const { requirements } = getUpgradeData(upgradeSlug);

  // Check character base stat against upgrade reqs
  for (const key in requirements) {
    const baseStat = key as keyof CharacterBaseStats;
    const reqValue = requirements[baseStat] || 0;

    if (totalBaseStats[baseStat] < reqValue) {
      return false;
    }
  }
  return true;
};

// INSTALLED UPGRADES

export const getInstalledUpgradesStats = (
  installedUpgrades: InstalledUpgradesData | null,
  currentBaseStats: CharacterBaseStats,
  totalBaseStats: CharacterBaseStats
) => {
  const stats: CharacterDerivedStats = {
    maxHealth: 0,
    maxAttackDamage: 0,
    minAttackDamage: 0,
    attackSpeed: 0,
    attackAccuracy: 0,
    maxWeakenParts: 0,
    minWeakenParts: 0,
    damageReduction: 0,
    weakenPartsReduction: 0,
    attackEvasion: 0,
    movementSpeed: 0,
    fasterRecharge: 0,
    energyMultiplier: 0,
  };

  if (!!installedUpgrades) {
    for (const partKey in installedUpgrades) {
      const part = partKey as PartTypes;
      const upgradeSlug = installedUpgrades[part];
      if (!!upgradeSlug) {
        const statMods = getUpgradeData(upgradeSlug)
          .derivedStats as CharacterDerivedStats;
        for (const key in statMods) {
          const statType = key as keyof CharacterDerivedStats;
          const statInfo = getDerivedStatData(statType);

          // Use weakened parts to determine efficacy of upgrade stats
          const percentage =
            totalBaseStats[statInfo.baseStat] === 0
              ? 0 // If the total is somehow 0, no effect from upgrades
              : currentBaseStats[statInfo.baseStat] /
                totalBaseStats[statInfo.baseStat];
          stats[statType] += statInfo.rounder(statMods[statType] * percentage);
        }
      }
    }
  }

  return stats;
};

// FILTER ITEMS

export const getSuppliesByType = (
  inventorySupplies: InventoryItemModel[],
  supplyType: SupplyTypes
) => {
  const repairSupplies = inventorySupplies
    .map((supply) => {
      return {
        ...supply,
        ...getSupplyData(supply.slug),
      };
    })
    .filter((supply) => supply.type === supplyType)
    .sort((a, b) => {
      if (!!a.payload && !!b.payload) {
        return a.payload - b.payload;
      } else {
        return a.credits - b.credits;
      }
    });

  return repairSupplies;
};

export const getFightSupplies = (inventorySupplies: InventoryItemModel[]) => {
  const fightSupplies = Object.entries(SUPPLY_TYPES)
    .filter(([, value]) => !!value.inFight)
    .reduce((acc, [key]) => {
      const supplyType = key as SupplyTypes;
      return {
        ...acc,
        [supplyType]: [],
      };
    }, {} as Record<SupplyTypes, string[]>);

  // Group supplies by their type property
  inventorySupplies.forEach((supply) => {
    const { type } = getSupplyData(supply.slug);
    if (fightSupplies[type]) {
      fightSupplies[type].push(supply.slug);
    }
  });

  // Sort supplies within each group
  Object.keys(fightSupplies).forEach((key) => {
    const supplyType = key as SupplyTypes;
    fightSupplies[supplyType].sort((a, b) => {
      const aData = getSupplyData(a);
      const bData = getSupplyData(b);
      // Sort by payload values if they exist, otherwise use credits
      if (!!aData.payload && !!bData.payload) {
        return aData.payload - bData.payload;
      } else {
        return aData.credits - bData.credits;
      }
    });
  });

  return fightSupplies;
};

// EMPLOY SUPPLIES

export const getPartsToRestore = (weakenedBaseStats: CharacterBaseStats) => {
  const partsToRestore = Object.entries(weakenedBaseStats)
    .filter(([key, value]) => {
      const weakenedAmount = value as number;
      return weakenedAmount > 0;
    })
    .sort(([, a], [, b]) => {
      // Highest to lowest part damage
      const aValue = a as number;
      const bValue = b as number;
      return bValue - aValue;
    });
  return partsToRestore;
};
