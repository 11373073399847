import { MobDropModel, ShipTypes } from "types";

export const ACADEMY_STARTER_PACKS: Record<ShipTypes, MobDropModel[]> = {
  hyperion: [
    { slug: "hyperion_academy_laser", quantity: 1 },
    { slug: "hyperion_academy_shield", quantity: 1 },
    { slug: "hyperion_academy_thruster", quantity: 1 },
    { slug: "hyperion_academy_core", quantity: 1 },
  ],
  nix: [
    { slug: "nix_academy_laser", quantity: 1 },
    { slug: "nix_academy_thruster", quantity: 1 },
    { slug: "nix_academy_scope", quantity: 1 },
    { slug: "nix_academy_core", quantity: 1 },
  ],
  titania: [
    { slug: "titania_academy_laser", quantity: 1 },
    { slug: "titania_academy_shield", quantity: 1 },
    { slug: "titania_academy_scope", quantity: 1 },
    { slug: "titania_academy_core", quantity: 1 },
  ],
  calypso: [
    { slug: "calypso_academy_shield", quantity: 1 },
    { slug: "calypso_academy_thruster", quantity: 1 },
    { slug: "calypso_academy_scope", quantity: 1 },
    { slug: "calypso_academy_core", quantity: 1 },
  ],
  atlas: [
    { slug: "atlas_academy_laser", quantity: 1 },
    { slug: "atlas_academy_shield", quantity: 1 },
    { slug: "atlas_academy_thruster", quantity: 1 },
    { slug: "atlas_academy_scope", quantity: 1 },
  ],
};
